import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link, navigate } from 'gatsby';
import LinkedIn from '@material-ui/icons/LinkedIn';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { bool, func } from 'prop-types';
import arrow from '../images/arrowRightWhite.png';
import { SUB_PAGES } from '../utils/constants';
// import Facebook from '@material-ui/icons/Facebook';
// import Instagram from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#141414',
    color: '#f8f8f8',
    display: 'flex',
    flexDirection: 'row',
  },
  menu: {
    position: 'relative',
    width: '100vw',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      '-moz-transition': 'all 0.6s',
      '-o-transition': 'all 0.6s',
      '-webkit-transition': 'all 0.6s',
      transition: 'all 0.6s',
    },
    [theme.breakpoints.up('md')]: {
      width: '22vw',
      '&:hover > li': {
        color: 'rgba(248,248,248, 0.5) !important',
        '&:hover': {
          color: '#fff !important',
        },
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
      '&:hover > li': {
        color: 'rgba(248,248,248, 0.5) !important',
        '&:hover': {
          color: '#fff !important',
        },
      },
    },
  },
  menuList: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      '&:hover > li': {
        color: 'rgba(248,248,248, 0.5) !important',
        '&:hover': {
          color: '#fff !important',
        },
      },
    },
  },
  navLink: {
    textDecoration: 'none',
    padding: '18px 10px 0px 40px',
    fontSize: '1rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px 8px 20px',
      fontSize: '0.84rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '4px 4px 4px 20px',
    },
  },
  cursorDefault: {
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      // cursor: 'default',
    },
  },
  subMenu: {
    color: '#fff',
    marginTop: 56,
    [theme.breakpoints.up('md')]: {
      '-moz-transition': 'width .4s ease-in-out',
      '-o-transition': 'width .4s ease-in-out',
      '-webkit-transition': 'width .4s ease-in-out',
      transition: 'width .4s ease-in-out',
      paddingTop: 77,
      width: '0',
      '&:hover > li': {
        color: 'rgba(248,248,248, 0.5) !important',
        '&:hover': {
          color: '#fff !important',
        },
      },
    },
  },
  subNavLink: {
    textDecoration: 'none',
    fontSize: '0.75rem',
    padding: '10px 0 10px 6px',
    'white-space': 'nowrap',
    width: '100%',
    opacity: 0,
    '-webkit-animation': '$fadein 0.4s ease-in-out forwards 160ms',
    '-moz-animation': '$fadein 0.4s ease-in-out forwards 160ms',
    '-ms-animation': '$fadein 0.4s ease-in-out forwards 160ms',
    '-o-animation': '$fadein 0.4s ease-in-out forwards 160ms',
    animation: '$fadein 0.4s ease-in-out forwards 160ms',
    transform: 'translateX(-20%)',
    [theme.breakpoints.down('md')]: {
      '-webkit-animation': '$fadeinmobile 600ms ease-in-out forwards 160ms',
      '-moz-animation': '$fadeinmobile 600ms ease-in-out forwards 160ms',
      '-ms-animation': '$fadeinmobile 600ms ease-in-out forwards 160ms',
      '-o-animation': '$fadeinmobile 600ms ease-in-out forwards 160ms',
      animation: '$fadeinmobile 600ms ease-in-out forwards 160ms',
      padding: '12px 12px 12px 20px',
      transform: 'translateX(30%)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px 8px 8px 20px',
    },
  },
  drawerLogo: {
    marginTop: '4rem',
  },
  socialIcon: {
    color: '#f8f8f8',
    paddingLeft: 0,
    cursor: 'pointer',
  },
  listItemIcon: {
    width: 10,
  },
  showSubMenu: {
    width: '17vw',
    [theme.breakpoints.up('xl')]: {
      width: '350px',
    },
    '@media (max-width:1100px) and (min-width:960px)': {
      width: '21vw',
    },
  },
  showSubMenuMobile: {
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  hideMenu: {
    transform: 'translateX(-100vw)',
  },
  fullHeight: {
    height: '100%',
  },
  textFooterHeader: {
    fontSize: '0.5em',
    margin: '0 0 8px',
    fontWeight: 'bold',
    cursor: 'default',
  },
  textFooter: {
    margin: '0 0 8px',
    cursor: 'default',
    fontSize: '0.8em',
    letterSpacing: '0.005em',
    color: '#f8f8f8',
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  gcFooter: {
    paddingLeft: 55,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
    },
  },
  ibClose: {
    color: '#f8f8f8',
    justifyContent: 'flex-end',
    right: 7,
    top: 10,
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 4,
      top: 20,
    },
  },
  ibBack: {
    color: '#f8f8f8',
    paddingLeft: 30,
    marginTop: 10,
    '-webkit-animation': '$fadeinBackBtn 600ms ease-in-out forwards 160ms',
    '-moz-animation': '$fadeinBackBtn 600ms ease-in-out forwards 160ms',
    '-ms-animation': '$fadeinBackBtn 600ms ease-in-out forwards 160ms',
    '-o-animation': '$fadeinBackBtn 600ms ease-in-out forwards 160ms',
    animation: '$fadeinBackBtn 600ms ease-in-out forwards 160ms',
    transform: 'translateX(0)',
    opacity: 0,
    zIndex: 999,
  },
  '@keyframes fadein': {
    from: { opacity: 0, transform: 'translateX(-20%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Firefox < 16 */
  '-moz-keyframes fadein': {
    from: { opacity: 0, transform: 'translateX(-20%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Safari, Chrome and Opera > 12.1 */
  '-webkit-keyframes fadein': {
    from: { opacity: 0, transform: 'translateX(-20%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Internet Explorer */
  '-ms-keyframes fadein': {
    from: { opacity: 0, transform: 'translateX(-20%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Opera < 12.1 */
  '-o-keyframes fadein': {
    from: { opacity: 0, transform: 'translateX(-20%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  '@keyframes fadeinmobile': {
    from: { opacity: 0, transform: 'translateX(30%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Firefox < 16 */
  '-moz-keyframes fadeinmobile': {
    from: { opacity: 0, transform: 'translateX(30%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Safari, Chrome and Opera > 12.1 */
  '-webkit-keyframes fadeinmobile': {
    from: { opacity: 0, transform: 'translateX(30%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Internet Explorer */
  '-ms-keyframes fadeinmobile': {
    from: { opacity: 0, transform: 'translateX(30%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  /* Opera < 12.1 */
  '-o-keyframes fadeinmobile': {
    from: { opacity: 0, transform: 'translateX(30%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
  '@keyframes fadeinBackBtn': {
    from: { opacity: 0, transform: 'translateX(-80vw)' },
    to: { opacity: 1, transform: 'translateX(-100vw)' },
  },
  /* Firefox < 16 */
  '-moz-keyframes fadeinBackBtn': {
    from: { opacity: 0, transform: 'translateX(-80vw)' },
    to: { opacity: 1, transform: 'translateX(-100vw)' },
  },
  /* Safari, Chrome and Opera > 12.1 */
  '-webkit-keyframes fadeinBackBtn': {
    from: { opacity: 0, transform: 'translateX(-80vw)' },
    to: { opacity: 1, transform: 'translateX(-100vw)' },
  },
  /* Internet Explorer */
  '-ms-keyframes fadeinBackBtn': {
    from: { opacity: 0, transform: 'translateX(-80vw)' },
    to: { opacity: 1, transform: 'translateX(-100vw)' },
  },
  /* Opera < 12.1 */
  '-o-keyframes fadeinBackBtn': {
    from: { opacity: 0, transform: 'translateX(-80vw)' },
    to: { opacity: 1, transform: 'translateX(-100vw)' },
  },
}));

const NavDrawer = ({ open, close }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [currentSubPages, setCurrentSubPages] = useState(null);

  const handleClose = () => {
    close();
    setCurrentSubPages(null);
  };

  const handleBackClick = () => {
    setCurrentSubPages(null);
  };

  const DefaultLink = ({ to, children, subPages }) => {
    if (matchesUpMd || !subPages) {
      return (
        <Link // eslint-disable-line
          to={to}
          onClick={() => {
            handleClose();
          }}
          className={[classes.navLink, subPages && classes.cursorDefault].join(
            ' '
          )}
          onMouseOver={() => {
            if (matchesUpMd) {
              setCurrentSubPages(subPages);
            }
          }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>{children.toUpperCase()}</Grid>
            {subPages && (
              <Grid item>
                <img
                  src={arrow}
                  alt="vow nav drawer arrow"
                  className={classes.listItemIcon}
                />
              </Grid>
            )}
          </Grid>
        </Link>
      );
    } else {
      return (
        <div
          className={[classes.navLink, subPages && classes.cursorDefault].join(
            ' '
          )}
          onClick={() => {
            if (!matchesUpMd) {
              if (subPages) {
                setCurrentSubPages(subPages);
              } else {
                handleClose();
              }
            } else {
              handleClose();
            }
          }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>{children.toUpperCase()}</Grid>
            {subPages && (
              <Grid item>
                <img
                  src={arrow}
                  alt="vow nav drawer arrow"
                  className={classes.listItemIcon}
                />
              </Grid>
            )}
          </Grid>
        </div>
      );
    }
  };

  const DefaultSubLink = ({ to, extra, children }) => {
    return (
      <Link
        to={to}
        onClick={() => {
          handleClose();
          navigate(to);
        }}
        className={classes.subNavLink}
      >
        {children}
      </Link>
    );
    // if (extra) {
    //   return (
    //     <Link to={extra} onClick={handleClose} className={classes.subNavLink}>
    //       VOICES OF VOW
    //     </Link>
    //   );
    // } else {
    //   return (
    //     <Link to={to} onClick={handleClose} className={classes.subNavLink}>
    //       {children.toUpperCase()}
    //     </Link>
    //   );
    // }
  };

  const Footer = () => (
    <Grid container direction="column" className={classes.gcFooter}>
      <Grid item>
        <p className={classes.textFooter}>Vow ASA</p>
        <p className={classes.textFooter}>+47 33 01 64 00</p>
        <p className={classes.textFooter}>info@vowasa.com</p>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <IconButton
              className={classes.socialIcon}
              target="_blank"
              href="https://www.linkedin.com/company/scanship/"
            >
              <LinkedIn />
            </IconButton>
          </Grid>
          <Grid item>
            {/* <IconButton
              className={classes.socialIcon}
              target="_blank"
              href="https://www.facebook.com/scanship.no/"
            >
              <Facebook />
            </IconButton> */}
          </Grid>
          <Grid item>
            {/* <IconButton
              className={classes.socialIcon}
              target="_blank"
              href="https://www.facebook.com/scanship.no/"
            >
              <Instagram />
            </IconButton> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const Menu = () => (
    <List className={classes.menuList} component="nav" dense>
      <ListItem>
        <DefaultLink to="/">Home</DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink to="/news-and-media" subPages={SUB_PAGES.NEWSANDMEDIA}>
          News & Media
        </DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink to="/solutions" subPages={SUB_PAGES.SOLUTIONS}>
          Solutions
        </DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink to="/our-brands" subPages={SUB_PAGES.OUR_BRANDS}>
          Our brands
        </DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink
          to="/about-us"
          extra="/voices-of-vow"
          subPages={SUB_PAGES.ABOUT_US}
        >
          About us
        </DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink to="/investor" subPages={SUB_PAGES.INVESTORS}>
          Investors
        </DefaultLink>
      </ListItem>
      <ListItem>
        <DefaultLink to="/sustainability" subPages={SUB_PAGES.SUSTAINABILITY}>
          Sustainability
        </DefaultLink>
      </ListItem>
    </List>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Grid container direction="column" justify="flex-end" wrap="nowrap">
        <IconButton className={classes.ibClose} onClick={handleClose}>
          <Close />
        </IconButton>
        <Grid
          container
          justify="space-between"
          direction="column"
          wrap="nowrap"
          className={[
            classes.menu,
            classes.fullHeight,
            currentSubPages && !matchesUpMd && classes.hideMenu,
          ].join(' ')}
        >
          <Grid item>
            <Menu />
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        {currentSubPages && !matchesUpMd && (
          <Grid item>
            <Grid container justify="space-between">
              <Grid item>
                <IconButton
                  className={classes.ibBack}
                  onClick={handleBackClick}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <List
            className={[
              classes.subMenu,
              currentSubPages && classes.showSubMenu,
              currentSubPages && !matchesUpMd && classes.showSubMenuMobile,
            ].join(' ')}
          >
            {currentSubPages &&
              currentSubPages.titles.map((title, index) => (
                <ListItem key={title}>
                  <DefaultSubLink to={currentSubPages.paths[index]}>
                    {title}
                  </DefaultSubLink>
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};

NavDrawer.propTypes = {
  open: bool,
  close: func.isRequired,
};

NavDrawer.defaultProps = {
  open: false,
};

export default NavDrawer;
