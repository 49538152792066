import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 60;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 0,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 375,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1),
  },
}));

const ModalSubscribe = ({ handleOpen, handleClose, open }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    // <div style={modalStyle} className={classes.paper}>
    //   <h2 id="simple-modal-title">Text in a modal</h2>
    //   <p id="simple-modal-description">
    //     Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    //   </p>
    // </div>
    <div style={modalStyle} className={classes.paper}>
      <iframe
        title="vow"
        style={{ width: '100%', height: '480px', marginLeft: 0 }}
        src="https://pr.globenewswire.com/NewsArchive/View/EPubdo1dr3VsTE3zC4xdYA==?iFrame=true"
        width="300"
        height="150"
        frameBorder="0"
      />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default ModalSubscribe;
