import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import Grid from '@material-ui/core/Grid';
import Norway from '../images/norway.png';
import ShareUp from '../images/shareUp.png';
import ShareDown from '../images/shareDown.png';

const useStyles = makeStyles(() => ({
  shareInfo: {
    backgroundColor: '#f8f8f8',
    borderRadius: '0px 0px 60px 0px',
    width: '348px',
    alignSelf: 'left',
  },
  flagNo: {
    width: '18px',
    display: 'inline-block',
    marginRight: '10px',
    verticalAlign: 'middle',
    '@media (max-width:750px)': {
      width: '14px',
    },
  },
  textNo: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingTop: '3px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    fontWeight: 'bold',
    marginRight: '6px',
    fontSize: '12px',
    color: '#141414',
    '@media (max-width:750px)': {
      fontSize: '10px',
      fontWeight: 'normal',
    },
  },
  textVow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingTop: '3px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    fontWeight: 'bold',
    marginRight: '6px',
    marginLeft: '10px',
    fontSize: '12px',
    color: '#141414',
    '@media (max-width:750px)': {
      fontSize: '10px',
      fontWeight: 'normal',
    },
  },
  textdata: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingTop: '3px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: '0px',
    marginRight: '10px',
    fontSize: '12px',
    color: '#141414',
    '@media (max-width:750px)': {
      fontSize: '10px',
    },
  },
  share: {
    width: '12px',
    display: 'inline-block',
    marginRight: '2px',
    verticalAlign: 'middle',
    '@media (max-width:750px)': {
      width: '10px',
    },
  },
}));

const navBarStock = () => {
  const classes = useStyles();
  const [sship, setSship] = useState('-');
  const [osebx, setOsebx] = useState(0);
  const [time, setTime] = useState('-');
  const [sharePrice, setSharePrice] = useState('-');
  const [previousClose, setPreviousClose] = useState(0);
  // const [timeStamp, setTimestamp] = useState(0);

  // useEffect(() => {
  //   fetch(
  //     'https://apidojo-yahoo-finance-v1.p.rapidapi.com/market/get-quotes?region=US&lang=en&symbols=vow.ol%252COSEBX.OL',
  //     {
  //       method: 'GET',
  //       headers: {
  //         'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
  //         'x-rapidapi-key':
  //           '04257964c8msh0e8f0015c838bdbp1eefeejsnf2c62b9f7b42',
  //       },
  //     }
  //   )
  //     .then(response => {
  //       if (response.status >= 200 && response.status <= 299) {
  //         return response.json();
  //       }
  //       throw Error(response.statusText);
  //     })
  //     .then(data => {
  //       const { result } = data.quoteResponse;
  //       setSship(result[0].regularMarketChangePercent.toFixed(2));
  //       setOsebx(result[1].regularMarketChangePercent.toFixed(2));
  //       setSharePrice(result[0].regularMarketPrice.toFixed(1));
  //       setPreviousClose(result[0].regularMarketPreviousClose.toFixed(2));
  //       setTimestamp(result[0].regularMarketTime);

  //       // Trying to format timestamp from Yahoo finance
  //       // const date = new Date(timeStamp * 1000);
  //       // const hours = date.getHours();
  //       // const minutes = `0${date.getMinutes()}`;
  //       // const seconds = `0${date.getSeconds()}`;
  //       // const formattedTime = `${hours - 1}:${minutes.substr(
  //       //   -2
  //       // )}:${seconds.substr(-2)}`;
  //       // console.log(formattedTime);

  //       const d = new Date();
  //       const n = d.toLocaleTimeString();
  //       setTime(n);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(
      'https://ir.oms.no/server/secure/components?auth=key%3dscanship_irn&lang=en&product=snapshot',
      {
        method: 'GET',
      }
    )
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        }
        throw Error(response.statusText);
      })
      .then(data => {
        setSharePrice(data.rows[0].values.LAST);
        setSship(data.rows[0].values.CHANGE_PCT.toFixed(2));
        // Timestamp solution, note oslo stock exchange closes 16:30:
        // const timeStamp = data.rows[0].values.TIME / 1000;
        // const date = new Date(timeStamp * 1000);
        // const hours = date.getHours();
        // const minutes = `0${date.getMinutes()}`;
        // const seconds = `0${date.getSeconds()}`;
        // const formattedTime = `${hours}:${minutes.substr(-2)}:${seconds.substr(
        //   -2
        // )}`;
        // setTime(formattedTime);

        const d = new Date();
        const n = d.toLocaleTimeString();
        setTime(n);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container>
      <Grid item className={classes.shareInfo}>
        <div style={{ paddingLeft: '30px', paddingBottom: 4 }}>
          <img src={Norway} className={classes.flagNo} alt="vow norway" />
          <p className={classes.textNo}>VOW ASA:</p>
          <p className={classes.textdata}>{`${sharePrice} NOK`}</p>
          <img
            src={Math.sign(sship) === -1 ? ShareDown : ShareUp}
            className={classes.share}
            alt="vow share"
          />
          <p className={classes.textdata}>{`${sship}% (${time})`}</p>
        </div>
      </Grid>
      <Grid item />
    </Grid>
  );
};

export default navBarStock;
