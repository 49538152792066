import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'gatsby';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import Container from '@material-ui/core/Container';
import logoWhite from '../images/logo1.png';
import NavDrawer from './navDrawer';
import navBarButtonWhite from '../images/navbarWhite.png';
import NavBarStock from './navBarStock';
import logoBlack from '../images/logoBlack1.png';
import navBarButtonBlack from '../images/navbarBlack.png';

const navHeight = 80;

const useStyles = makeStyles(() => ({
  toolbar: {
    flexGrow: 1,
  },
  link: {
    margin: '2px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    marginLeft: '0.2em',
    width: '100px',
  },
  title: {
    visibility: 'hidden',
  },
  hamburger: {
    width: '50px',
    height: '50px',
    '&:hover': {},
  },
  container: {
    height: navHeight,
  },
  root: {
    position: 'relative',
  },
  rootNonLandingPage: {
    height: navHeight,
  },
  appBar: {
    background: 'transparent',
    // background: 'red',
    left: 0,
    width: '100%',
  },
}));
const mobileMenuId = 'primary-search-account-menu-mobile';

const NavBar2 = ({ location }) => {
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);
  let isLandingPage;
  if (typeof location !== 'undefined') {
    isLandingPage = location.pathname === '/';
  }
  const isCampaignPage = location.pathname.includes('/campaign/');

  return (
    <>
      <NavDrawer open={navOpen} close={() => setNavOpen(false)} />
      <Container
        className={[
          classes.root,
          !isLandingPage && classes.rootNonLandingPage,
        ].join(' ')}
        maxWidth="false"
      >
        <AppBar
          position="absolute"
          elevation={0}
          className={[classes.appBar, !isLandingPage && classes.container].join(
            ' '
          )}
        >
          <>
            <NavBarStock />
            <Toolbar>
              <nav className={classes.toolbar}>
                <IconButton
                  className={classes.hamburger}
                  aria-label="Show menu"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={() => setNavOpen(true)}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <img
                    src={
                      isLandingPage || isCampaignPage
                        ? navBarButtonWhite
                        : navBarButtonBlack
                    }
                    alt="vow menu"
                    style={{ width: 28, height: 28, borderRadius: 14 }}
                  />
                </IconButton>
              </nav>
              <div>
                <Link variant="button" to="/">
                  <img
                    src={
                      isLandingPage || isCampaignPage ? logoWhite : logoBlack
                    }
                    className={classes.logo}
                    alt="vow logo"
                  />
                </Link>
              </div>
            </Toolbar>
          </>
        </AppBar>
      </Container>
    </>
  );
};

export default NavBar2;
