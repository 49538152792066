import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import LinkedIn from '@material-ui/icons/LinkedIn';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import ModalSubscribe from './modalSubscribe';
import Footerlogo from '../images/logo1.png';
import { COLOR_CORAL } from '../utils/constants';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#191919',
    minHeight: 400,
    color: '#F8F8F8',
    paddingTop: '120px',
    paddingBottom: '40px',
    paddingLeft: '30px',
    paddingRight: '30px',
    '@media (max-width:800px)': {
      paddingTop: '3em',
      paddingBottom: '3em',
    },
  },
  two: {
    backgroundColor: '#141414',
    minHeight: 400,
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '250px',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'space-between',
    '@media (max-width:800px)': {
      flexDirection: 'row',
      height: '80px',
    },
  },
  addressSection: {
    height: '250px',
    '@media (max-width:800px)': {
      paddingTop: '20px',
      minHeight: '200px',
    },
  },
  businessSection: {
    height: '250px',
    '@media (max-width:800px)': {
      paddingTop: '20px',
      minHeight: '200px',
    },
  },
  footerNavSection: {
    height: '250px',
    lineHeight: '2em',
    paddingLeft: '4em',
    '@media (min-width:600px) and (max-width:960px)': {
      paddingTop: '40px',
      paddingLeft: '0.2em',
    },
    '@media (max-width:600px)': {
      paddingTop: '20px',
      paddingLeft: '0.2em',
    },
  },
  copyRightSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '256px',
    justifyContent: 'space-between',
    // alignItems: 'stretch',
    // alignContent: 'space-between',
    '@media (max-width:800px)': {
      height: '110px',
    },
  },
  address: {
    fontStyle: 'normal',
    textAlign: 'left',
    '& p': {
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
  },
  logoblack: {
    width: '100px',
  },
  socialIcon: {
    color: '#F8F8F8 !important',
    '@media (max-width:800px)': {
      marginTop: '-40px',
    },
  },
  copyRight: {
    marginTop: 40,
    textAlign: 'right',
    '@media (max-width:800px)': {
      // textAlign: 'left',
      marginTop: 134,
    },
  },
  linkPage: {
    textDecoration: 'none',
  },
  subscribeBtn: {
    // maxWidth: 80,
    marginTop: 30,
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 20px 8px 20px',
    backgroundColor: COLOR_CORAL,
    // pointerEvents: 'none',
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
    '@media (max-width:800px)': {
      marginTop: 20,
    },
  },
  subscribeBtnContainer: {
    textAlign: 'right',
    color: 'white',
    '@media (max-width:800px)': {
      // textAlign: 'left',
    },
  },
  subscribeText: {
    textAlign: 'right',
    '@media (max-width:800px)': {
      // textAlign: 'left',
      marginTop: 24,
    },
  },
}));

const Footer2 = ({ data }) => {
  const classes = useStyles();
  const footerData = data.allContentfulFooter.edges[0].node;
  const [isOpen, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const handleOpenSubscribe = () => {
    toggleModal();
  };

  const {
    columnOneTitle,
    columnOneLine1,
    columnOneLine2,
    columnOneLine3,
    columnOneLine4,
    columnTwoTitle,
    columnTwoLine1,
    columnTwoLine2,
    columnTwoLine3,
    columnTwoLine4,
    copyrightYear,
  } = footerData;

  return (
    <>
      <Grid
        container
        justify="space-between"
        // alignItems="center"
        direction="row"
        className={classes.container}
      >
        <Grid
          item
          className={classes.logoSection}
          // style={{ backgroundColor: 'brown' }}
          xs={12}
          // sm={6}
          md={3}
        >
          <div>
            <img
              src={Footerlogo}
              alt="vow footer"
              className={classes.logoblack}
            />
            <span style={{ visibility: 'hidden' }}>vow scanship etia</span>
          </div>
          <div>
            <br />
            <IconButton
              className={classes.socialIcon}
              target="_blank"
              href="https://www.linkedin.com/company/scanship/"
            >
              <LinkedIn />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2} className={classes.addressSection}>
          <address className={classes.address}>
            <span
              style={{
                fontSize: '18px',
              }}
            >
              {footerData.columnOneTitle.toUpperCase()}
            </span>
            <br />
            <br />
            {/* <p>Lysaker Torg 12</p> */}
            <p>{footerData.columnOneLine1}</p>
            {/* <p>1366 Lysaker NORWAY</p> */}
            <p>{footerData.columnOneLine2}</p>
            <br />
            <br />
            <a
              href="mailto:info@vowasa.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* info@vowasa.com */}
              {footerData.columnOneLine3}
            </a>
            <br />
            {/* <p>Org. nr. 996 819 000</p> */}
            <p>{footerData.columnOneLine4}</p>
            <br />
          </address>
        </Grid>
        <Grid item xs={12} sm={6} md={2} className={classes.businessSection}>
          <address className={classes.address}>
            {/* <span style={{ fontSize: '18px' }}>Business inquiries</span> */}
            <span style={{ fontSize: '18px' }}>
              {footerData.columnTwoTitle.toUpperCase()}
            </span>
            <br />
            <br />
            {/* <p>Henrik Badin</p> */}
            <p>{footerData.columnTwoLine1}</p>
            {/* <p>Chief Executive Officer (CEO)</p> */}
            <p>{footerData.columnTwoLine2}</p>
            {/* <p>+47 90 78 98 25</p> */}
            <p>{footerData.columnTwoLine3}</p>
            <a
              href="mailto:info@vowasa.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* henrik.badin@vowasa.com */}
              {footerData.columnTwoLine4}
            </a>
          </address>
        </Grid>
        <Grid item xs={6} sm={6} md={2} className={classes.footerNavSection}>
          <Link className={classes.linkPage} to="/news">
            News
          </Link>
          <br />
          <Link className={classes.linkPage} to="/business">
            Our business
          </Link>
          <br />
          <Link className={classes.linkPage} to="/solutions">
            Solutions
          </Link>
          <br />
          <Link className={classes.linkPage} to="/about-us">
            About us
          </Link>
          <br />
          <Link className={classes.linkPage} to="/investor">
            Investors
          </Link>
          <br />
          <Link className={classes.linkPage} to="/sustainability">
            Sustainability
          </Link>
          <br />
          <Link className={classes.linkPage} to="/podcast">
            Podcast
          </Link>
          <br />
          <Link className={classes.linkPage} to="/work-with-us">
            Work With Us
          </Link>
          <br />
        </Grid>
        <Grid item xs={6} md={2} className={classes.copyRightSection}>
          {/* <div /> */}
          <div className={classes.subscribeText}>
            SUBSCRIBE FOR NEWS
            <div className={classes.subscribeBtnContainer}>
              <Button
                variant="contained"
                className={classes.subscribeBtn}
                onClick={handleOpenSubscribe}
              >
                Subscribe
              </Button>
              <ModalSubscribe open={isOpen} handleClose={toggleModal} />
            </div>
          </div>

          <div className={classes.copyRight}>{footerData.copyrightYear}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer2;
