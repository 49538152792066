// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-about-us-js": () => import("./../../../src/pages/about-us/about-us.js" /* webpackChunkName: "component---src-pages-about-us-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-management-and-board-js": () => import("./../../../src/pages/about-us/management-and-board.js" /* webpackChunkName: "component---src-pages-about-us-management-and-board-js" */),
  "component---src-pages-about-us-voices-of-vow-js": () => import("./../../../src/pages/about-us/voices-of-vow.js" /* webpackChunkName: "component---src-pages-about-us-voices-of-vow-js" */),
  "component---src-pages-about-us-work-with-us-js": () => import("./../../../src/pages/about-us/work-with-us.js" /* webpackChunkName: "component---src-pages-about-us-work-with-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-financial-calendar-js": () => import("./../../../src/pages/investor/financial-calendar.js" /* webpackChunkName: "component---src-pages-investor-financial-calendar-js" */),
  "component---src-pages-investor-general-meetings-js": () => import("./../../../src/pages/investor/general-meetings.js" /* webpackChunkName: "component---src-pages-investor-general-meetings-js" */),
  "component---src-pages-investor-index-js": () => import("./../../../src/pages/investor/index.js" /* webpackChunkName: "component---src-pages-investor-index-js" */),
  "component---src-pages-investor-ir-contacts-js": () => import("./../../../src/pages/investor/ir-contacts.js" /* webpackChunkName: "component---src-pages-investor-ir-contacts-js" */),
  "component---src-pages-investor-news-js": () => import("./../../../src/pages/investor/news.js" /* webpackChunkName: "component---src-pages-investor-news-js" */),
  "component---src-pages-investor-results-reports-js": () => import("./../../../src/pages/investor/results-reports.js" /* webpackChunkName: "component---src-pages-investor-results-reports-js" */),
  "component---src-pages-investor-share-information-js": () => import("./../../../src/pages/investor/share-information.js" /* webpackChunkName: "component---src-pages-investor-share-information-js" */),
  "component---src-pages-news-and-media-index-js": () => import("./../../../src/pages/news-and-media/index.js" /* webpackChunkName: "component---src-pages-news-and-media-index-js" */),
  "component---src-pages-news-and-media-news-js": () => import("./../../../src/pages/news-and-media/news.js" /* webpackChunkName: "component---src-pages-news-and-media-news-js" */),
  "component---src-pages-news-and-media-podcast-js": () => import("./../../../src/pages/news-and-media/podcast.js" /* webpackChunkName: "component---src-pages-news-and-media-podcast-js" */),
  "component---src-pages-news-and-media-video-js": () => import("./../../../src/pages/news-and-media/video.js" /* webpackChunkName: "component---src-pages-news-and-media-video-js" */),
  "component---src-pages-our-brands-ascodero-js": () => import("./../../../src/pages/our-brands/ascodero.js" /* webpackChunkName: "component---src-pages-our-brands-ascodero-js" */),
  "component---src-pages-our-brands-biogreen-js": () => import("./../../../src/pages/our-brands/biogreen.js" /* webpackChunkName: "component---src-pages-our-brands-biogreen-js" */),
  "component---src-pages-our-brands-chevensen-js": () => import("./../../../src/pages/our-brands/chevensen.js" /* webpackChunkName: "component---src-pages-our-brands-chevensen-js" */),
  "component---src-pages-our-brands-index-js": () => import("./../../../src/pages/our-brands/index.js" /* webpackChunkName: "component---src-pages-our-brands-index-js" */),
  "component---src-pages-our-brands-ozosteril-js": () => import("./../../../src/pages/our-brands/ozosteril.js" /* webpackChunkName: "component---src-pages-our-brands-ozosteril-js" */),
  "component---src-pages-our-brands-safesteril-js": () => import("./../../../src/pages/our-brands/safesteril.js" /* webpackChunkName: "component---src-pages-our-brands-safesteril-js" */),
  "component---src-pages-our-brands-scanship-js": () => import("./../../../src/pages/our-brands/scanship.js" /* webpackChunkName: "component---src-pages-our-brands-scanship-js" */),
  "component---src-pages-our-brands-spirajoule-js": () => import("./../../../src/pages/our-brands/spirajoule.js" /* webpackChunkName: "component---src-pages-our-brands-spirajoule-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-etia-land-based-js": () => import("./../../../src/pages/solutions/etia-land-based.js" /* webpackChunkName: "component---src-pages-solutions-etia-land-based-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-scanship-sea-based-js": () => import("./../../../src/pages/solutions/scanship-sea-based.js" /* webpackChunkName: "component---src-pages-solutions-scanship-sea-based-js" */),
  "component---src-pages-sustainability-development-goals-js": () => import("./../../../src/pages/sustainability/development-goals.js" /* webpackChunkName: "component---src-pages-sustainability-development-goals-js" */),
  "component---src-pages-sustainability-esg-resources-js": () => import("./../../../src/pages/sustainability/esg-resources.js" /* webpackChunkName: "component---src-pages-sustainability-esg-resources-js" */),
  "component---src-pages-sustainability-grievance-mechanism-js": () => import("./../../../src/pages/sustainability/grievance-mechanism.js" /* webpackChunkName: "component---src-pages-sustainability-grievance-mechanism-js" */),
  "component---src-pages-sustainability-index-js": () => import("./../../../src/pages/sustainability/index.js" /* webpackChunkName: "component---src-pages-sustainability-index-js" */),
  "component---src-pages-sustainability-our-approach-js": () => import("./../../../src/pages/sustainability/our-approach.js" /* webpackChunkName: "component---src-pages-sustainability-our-approach-js" */),
  "component---src-pages-sustainability-social-responsibility-js": () => import("./../../../src/pages/sustainability/social-responsibility.js" /* webpackChunkName: "component---src-pages-sustainability-social-responsibility-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-campaign-template-js": () => import("./../../../src/templates/campaign-template.js" /* webpackChunkName: "component---src-templates-campaign-template-js" */),
  "component---src-templates-vacancy-template-js": () => import("./../../../src/templates/vacancy-template.js" /* webpackChunkName: "component---src-templates-vacancy-template-js" */),
  "component---src-templates-voices-of-vow-template-js": () => import("./../../../src/templates/voices-of-vow-template.js" /* webpackChunkName: "component---src-templates-voices-of-vow-template-js" */)
}

