import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles/index';
import Container from '@material-ui/core/Container';
import Footer2 from './footer2';
import Navbar2 from './navBar2';
import './base.css';

const useStyles = makeStyles(() => ({
  pageContainer: {
    padding: '0 !important',
  },
  margin: {
    height: '96px',
  },
}));

const Page = ({ location, children }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter {
        edges {
          node {
            columnOneTitle
            columnOneLine1
            columnOneLine2
            columnOneLine3
            columnOneLine4
            columnTwoTitle
            columnTwoLine1
            columnTwoLine2
            columnTwoLine3
            columnTwoLine4
            copyrightYear
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="false" className={classes.pageContainer}>
      <Navbar2 location={location} />
      {children}
      <div className={classes.margin} />
      <Footer2 data={data} />
    </Container>
  );
};

export default Page;
